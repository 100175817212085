var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4",attrs:{"id":"project-files"}},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mt-5 ml-2 mr-2 m_brown",attrs:{"icon":"","disabled":_vm.campaign.id === ''},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-left-bold-circle")])],1),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.$cap(_vm.campaign.name)))]),_c('i',[_vm._v("Livrables")])])],1)]),_c('div',{staticClass:"mb-5 mt-5 pl-2"},[(_vm.$hasRight('files.uploadDeliverables'))?_c('MediumButtonSlot',{staticClass:"mr-2",on:{"click":function($event){_vm.showAddDocument = true}}},[_vm._v(" Ajouter des documents ")]):_vm._e(),(_vm.$hasRight('files.generateDeliverablesShareLink'))?_c('MediumButtonSlot',{staticClass:"mr-2",on:{"click":function($event){_vm.isSelecting = !_vm.isSelecting}}},[_vm._v(" "+_vm._s(_vm.isSelecting ? "annuler" : "partager")+" ")]):_vm._e(),(
        _vm.$hasRight('files.generateDeliverablesShareLink') &&
        _vm.download.selected.length > 0
      )?_c('HighButtonSlot',{staticClass:"mr-2",on:{"click":function($event){_vm.download.showDownloadModale = true}}},[_vm._v(" Générer le lien ")]):_vm._e()],1),_c('div',{staticClass:"pl-2"},[_c('v-data-table',{staticClass:"elevation-0 bordered",attrs:{"headers":_vm.headers,"items":_vm.documents,"items-per-page":20,"locale":"fr-FR","search":_vm.search,"no-data-text":"Aucun fichier n'a été importé","show-select":_vm.isSelecting,"footer-props":{
        'disable-items-per-page': true
      },"hide-default-footer":_vm.documents.length <= 20},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"clearable":"","label":"Rechercher un fichier"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.size",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bytesToSize(item.size))+" ")]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.createdAt).format("llll"))+" ")]}},{key:"item.download",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","fab":"","small":""},on:{"click":function($event){return _vm.downloadDocument(item.s3Path, item.name)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-download ")])],1)]}}]),model:{value:(_vm.download.selected),callback:function ($$v) {_vm.$set(_vm.download, "selected", $$v)},expression:"download.selected"}})],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showAddDocument),callback:function ($$v) {_vm.showAddDocument=$$v},expression:"showAddDocument"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Ajouter de nouveaux documents ")]),_c('v-form',{ref:"sendDocumentForm",on:{"submit":function($event){$event.preventDefault();return _vm.sendDocument()}}},[_c('v-card-text',[_c('v-file-input',{attrs:{"label":"Ajoutez un ou plusieurs fichiers","show-size":"","truncate-length":"15","rules":_vm.rules,"multiple":""},model:{value:(_vm.documentFiles),callback:function ($$v) {_vm.documentFiles=$$v},expression:"documentFiles"}})],1),_c('v-divider'),_c('v-card-actions',[_c('low-button-slot',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showAddDocument = false}}},[_vm._v(" Annuler ")]),_c('v-spacer'),_c('HighButtonSlot',{attrs:{"_disabled":_vm.isDocumentUploading || _vm.documentFiles.length === 0,"_type":'submit'}},[(_vm.isDocumentUploading)?_c('v-icon',{staticClass:"rotating-icon"},[_vm._v("mdi-refresh")]):_vm._e(),_vm._v(" Envoyer ")],1)],1)],1)],1)],1),(_vm.download.showDownloadModale)?_c('CreateDownloadLinkModale',{attrs:{"files":_vm.download.selected},on:{"newLink":_vm.displayNewLink,"closePopin":function($event){_vm.download.showDownloadModale = false}}}):_vm._e(),_c('v-snackbar',{attrs:{"vertical":"","timeout":"8000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [(!_vm.linkSnackbar.copied)?_c('MediumButtonSlot',_vm._b({on:{"click":function($event){return _vm.copyTextToClipboard()}}},'MediumButtonSlot',attrs,false),[_vm._v("Copier")]):_c('MediumButtonSlot',_vm._b({on:{"click":function($event){_vm.linkSnackbar.shouldDisplay = false}}},'MediumButtonSlot',attrs,false),[_vm._v("Fermer")])]}}]),model:{value:(_vm.linkSnackbar.shouldDisplay),callback:function ($$v) {_vm.$set(_vm.linkSnackbar, "shouldDisplay", $$v)},expression:"linkSnackbar.shouldDisplay"}},[(!_vm.linkSnackbar.copied)?_c('p',[_vm._v(" Lien de téléchargement créé ! "),_c('br'),_c('a',{ref:"downloadLink",attrs:{"href":_vm.linkSnackbar.link}},[_vm._v(_vm._s(_vm.linkSnackbar.link))])]):_c('p',[_vm._v("Lien copié dans le presse papier !")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }