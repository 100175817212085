<template>
  <div id="project-files" class="ma-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          @click="$router.back()"
          :disabled="campaign.id === ''"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(campaign.name) }}</h1>
          <i>Livrables</i>
        </div>
      </div>
    </div>

    <div class="mb-5 mt-5 pl-2">
      <MediumButtonSlot
        v-if="$hasRight('files.uploadDeliverables')"
        class="mr-2"
        @click="showAddDocument = true"
      >
        Ajouter des documents
      </MediumButtonSlot>
      <MediumButtonSlot
        v-if="$hasRight('files.generateDeliverablesShareLink')"
        class="mr-2"
        @click="isSelecting = !isSelecting"
      >
        {{ isSelecting ? "annuler" : "partager" }}
      </MediumButtonSlot>
      <HighButtonSlot
        v-if="
          $hasRight('files.generateDeliverablesShareLink') &&
          download.selected.length > 0
        "
        class="mr-2"
        @click="download.showDownloadModale = true"
      >
        Générer le lien
      </HighButtonSlot>
    </div>
    <div class="pl-2">
      <v-data-table
        :headers="headers"
        :items="documents"
        :items-per-page="20"
        locale="fr-FR"
        class="elevation-0 bordered"
        :search="search"
        no-data-text="Aucun fichier n'a été importé"
        :show-select="isSelecting"
        :footer-props="{
          'disable-items-per-page': true
        }"
        v-model="download.selected"
        :hide-default-footer="documents.length <= 20"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            clearable
            label="Rechercher un fichier"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.size="{ item }">
          {{ bytesToSize(item.size) }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ moment(item.createdAt).format("llll") }}
        </template>

        <template v-slot:item.download="{ item }">
          <v-btn
            class="mx-2"
            depressed
            fab
            small
            @click="downloadDocument(item.s3Path, item.name)"
          >
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="showAddDocument" width="800">
      <v-card>
        <v-card-title> Ajouter de nouveaux documents </v-card-title>

        <v-form ref="sendDocumentForm" @submit.prevent="sendDocument()">
          <v-card-text>
            <v-file-input
              v-model="documentFiles"
              label="Ajoutez un ou plusieurs fichiers"
              show-size
              truncate-length="15"
              :rules="rules"
              multiple
            ></v-file-input>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <low-button-slot
              color="primary"
              text
              @click="showAddDocument = false"
            >
              Annuler
            </low-button-slot>
            <v-spacer></v-spacer>
            <HighButtonSlot
              :_disabled="isDocumentUploading || documentFiles.length === 0"
              :_type="'submit'"
              ><v-icon class="rotating-icon" v-if="isDocumentUploading"
                >mdi-refresh</v-icon
              >
              Envoyer
            </HighButtonSlot>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <CreateDownloadLinkModale
      :files="download.selected"
      @newLink="displayNewLink"
      v-if="download.showDownloadModale"
      @closePopin="download.showDownloadModale = false"
    />
    <v-snackbar vertical timeout="8000" v-model="linkSnackbar.shouldDisplay">
      <p v-if="!linkSnackbar.copied">
        Lien de téléchargement créé ! <br />
        <a ref="downloadLink" :href="linkSnackbar.link">{{
          linkSnackbar.link
        }}</a>
      </p>
      <p v-else>Lien copié dans le presse papier !</p>

      <template v-slot:action="{ attrs }">
        <MediumButtonSlot
          v-if="!linkSnackbar.copied"
          v-bind="attrs"
          @click="copyTextToClipboard()"
          >Copier</MediumButtonSlot
        >
        <MediumButtonSlot
          v-else
          v-bind="attrs"
          @click="linkSnackbar.shouldDisplay = false"
          >Fermer</MediumButtonSlot
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import CreateDownloadLinkModale from "@/components/entities/file/CreateDownloadLink.modal.vue";

export default {
  name: "ProjectFiles",
  components: {
    CreateDownloadLinkModale
  },
  data() {
    return {
      linkSnackbar: {
        shouldDisplay: false,
        link: "",
        copied: false
      },
      loading: true,
      campaign: {},
      search: "",
      showAddDocument: false,
      isSelecting: false,
      download: {
        selected: [],
        showDownloadModale: false
      },
      documentFiles: [],
      isDocumentUploading: false,
      headers: [
        { text: "Nom", value: "name" },
        { text: "Date de création", value: "createdAt" },
        { text: "Taille du fichier", value: "size" },
        { text: "Télécharger", value: "download" }
      ],
      documents: [],
      rules: [v => !!v || "Une valeur est requise"]
    };
  },
  watch: {
    isSelecting() {
      if (this.isSelecting === false) this.download.selected = [];
    }
  },
  mounted() {
    this.getCampaign();
    this.getDocuments();
  },
  methods: {
    async getDocuments() {
      this.documents = await this.$api.documents.findByCampaign(
        this.$route.params.id,
        "delivery"
      );
    },
    async getCampaign() {
      this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
    },
    displayNewLink(link) {
      this.linkSnackbar.copied = false;
      this.linkSnackbar.link = link;
      this.linkSnackbar.shouldDisplay = true;
      this.download.showDownloadModale = false;
    },
    fallbackCopyTextToClipboard() {
      this.$refs.downloadLink.focus();
      this.$refs.downloadLink.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
        this.linkSnackbar.copied = true;
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
    },
    copyTextToClipboard() {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard();
        return;
      }
      navigator.clipboard.writeText(this.linkSnackbar.link);
      this.linkSnackbar.copied = true;
    },
    downloadDocument(documentPath, documentName) {
      this.$api.documents.download(documentPath).then(documentContent => {
        const url = window.URL.createObjectURL(new Blob([documentContent]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName);
        link.click();
      });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    sendDocument() {
      if (this.$refs.sendDocumentForm.validate() === false) {
        return;
      }
      this.isDocumentUploading = true;

      var re = /(?:\.([^.]+))?$/;

      this.documentFiles.forEach(documentFile => {
        var formData = new FormData();
        formData.append("file", documentFile);
        formData.append("campaignId", this.campaign.id);
        formData.append("category", "delivery");

        var extension = re.exec(documentFile.name)[1];

        this.$api.documents
          .upload(formData, {
            name: documentFile.name,
            extension: extension,
            size: documentFile.size,
            category: "delivery",
            campaignId: this.campaign.id,
            requiredDocumentId: null
          })
          .then(document => {
            this.showAddDocument = false;
            this.documents.push(document);
            this.isDocumentUploading = false;
          })
          .catch(() => (this.isDocumentUploading = false));
      });
    }
  }
};
</script>

<style lang="scss">
#project-files {
  height: 100%;
}

.rotating-icon {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.v-data-footer {
  &__select {
    visibility: hidden !important;
  }
  &__pagination {
    visibility: hidden !important;
  }
}

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
