<template>
  <div class="EditPopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Partager des documents</h3>
      </v-card-title>
      <v-expansion-panels v-model="isPanelOpen">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Documents ajoutés ({{ files.length }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li :key="file.id" v-for="file in files">{{ file.name }}</li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />
      <v-card-actions>
        <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="confirmModifications()">
          Valider
        </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["files"],
  data() {
    return {
      isFormValid: false,
      rules: {
        validEmail: (value) => {
          if (false === this.newDownloadLink.shouldSendEmail) return true;
          if (value && value.length >= 0) {
            const pattern =
              /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            return (
              pattern.test(value) || "Veuillez saisir une adresse mail valide"
            );
          } else return "Veuillez saisir une adresse mail valide";
        },
      },
      isPanelOpen: false,
      newDownloadLink: {
        documentsIds: this.files.map((file) => file.id),
        authorId: this.$store.state.user.current.userId,
        shouldSendEmail: false,
        receiverEmail: null,
        mailContent: null,
      },
      users: [],
    };
  },
  computed: {},
  methods: {
    async confirmModifications() {
      const link = this.$api.documents.generateDownloadLink(
        this.newDownloadLink
      );
      const linkUrl = window.location.origin + "/download?token=" + link.id;

      this.$emit("newLink", linkUrl);
      this.closePopin();
    },
    closePopin() {
      this.$emit("closePopin");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
